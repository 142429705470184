import {isEmptyString} from '@ivosabev/helpers/isEmptyString';
import {isEqual} from '@ivosabev/helpers/isEqual';
import {useFetcher} from '@remix-run/react';
import {type ChangeEvent, useEffect, useState} from 'react';
import * as gtag from '#/app/helpers/gtag.client.js';

export const useAdvancedFetcher = ({key, schema}: {key: string; schema: any}) => {
  const fetcher = useFetcher({key});

  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const [isFormStarted, setIsFormStarted] = useState(false);

  const [submittedData, setSubmittedData] = useState({});
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [errors, setErrors] = useState<{fieldErrors: Record<string, string>; formErrors: string[]}>({fieldErrors: {}, formErrors: []});

  useEffect(() => {
    // @ts-expect-error
    setErrors(fetcher.data?.errors);

  }, [fetcher.data]);

  useEffect(() => {
    if (fetcher.state === 'submitting') {
      setIsFormDirty(false);
    } else if (fetcher.state === 'idle' && isSubmitting2) {
      const hasErrors = (errors?.formErrors || []).length > 0 || Object.values(errors?.fieldErrors ?? {}).some((error) => !!error);
      if (hasErrors) {
        gtag.event({action: 'form_error', errors: JSON.stringify(errors), form_id: key});
      } else {
        gtag.event({action: 'form_submit', form_id: key});
      }
      setIsSubmitting2(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.state]);

  const handleChange = ({target: {name, value}}: ChangeEvent<HTMLInputElement>) => {
    if (!isFormStarted) {
      setIsFormStarted(true);
      gtag.event({action: 'form_start', form_id: key});
    }
    const fieldSchema = schema.pick({[name]: true});
    const fieldError = isEmptyString(value)
      ? undefined
      : fieldSchema.safeParse({[name]: value}).error?.flatten()?.fieldErrors[name] || undefined;
    setErrors({...errors, fieldErrors: {[name]: fieldError}});
    setIsFormDirty(true);
  };

  const handleSubmit = (e: any) => {
    const formData = new FormData(e.target.form);
    const data = Object.fromEntries(formData);
    if (!isEqual(data, submittedData)) {
      setSubmittedData(data);
      const newErrors = schema.safeParse(data).error?.flatten() || undefined;
      const _errors = {...errors, ...newErrors};
      setErrors(_errors);
      const hasErrors = (_errors?.formErrors || []).length > 0 || Object.values(_errors?.fieldErrors ?? {}).some((error) => !!error);
      if (hasErrors) {
        e.preventDefault();
      }
      setIsSubmitting2(true);
    } else {
      e.preventDefault();
    }
  };

  const isSubmitting = fetcher.state === 'submitting';
  const isSubmitted = fetcher.state === 'idle' && fetcher.data && !isFormDirty;
  // const isFormFilled = ???;

  return {...fetcher, data: {...(fetcher?.data || {}), errors}, handleChange, handleSubmit, isFormDirty, isSubmitted, isSubmitting};
};
